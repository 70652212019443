import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VoucherAttachment } from '../models/interfaces/Voucher';
import { RootState } from '../store';

interface BookkeepingState {
    voucherAttachments : VoucherAttachment[] | undefined;
}

const initialState: BookkeepingState = {
    voucherAttachments : undefined
};

const bookkeepingSlice = createSlice({
    name : 'bookkeeping',
    initialState,
    reducers : {
        setVoucherAttachments : (state, action : PayloadAction<VoucherAttachment | undefined>) => {
            if(action.payload){
                const currentState = state.voucherAttachments?.find(x => x.voucherId === action.payload?.voucherId);
                if(currentState){
                    currentState.attachments = action.payload.attachments;
                }else{
                    state.voucherAttachments = [...(state.voucherAttachments || []), action.payload];
                }
            }else{
                state.voucherAttachments = action.payload;
            }
            
        }
    }
});
export default bookkeepingSlice.reducer;


export const { setVoucherAttachments} = bookkeepingSlice.actions;
export const selectAllVoucherAttachments = (state : RootState) => state.bookkeeping.voucherAttachments;